import React from "react";
import "../flutter.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import ScrollDown from "../scroll-icon/ScrollDown";
import { motion, useScroll, useTransform } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
export default function CipherGred() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();

  const isDesktop = useMediaQuery("(min-width:600px)");

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-400, 850] : [-200, 350]
  );

  return (
    <motion.div
      style={
        !isDesktop && {
          marginRight: "250px",
        }
      }
      id="cipherGredProj"
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="phone">
        <img className="mobileTemplate" src={phone} alt="" />
        <div className="phoneScreen">
          <img src={scan} alt="" className="phoneApp" />
        </div>

        <ScrollDown device={"Phone"} />
      </motion.div>
      <div id="cipherDeets" className="projectDetail">
        <h1 className="projectTitle">CipherGred</h1>
        <p className="projectDesc">
          Flutter application built to detect harmful ingredients in food
          labels.
        </p>

        <button
          onClick={() => navigate("/MoreDetailsOnCipherGred")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
