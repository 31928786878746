import React from "react";
// import "../flutter.css";
import "../projects.css";
import laptop from "../assets/laptop.png";
import scan from "./assets/combined.jpg";
import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import Aos from "aos";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";

export default function CAAS() {
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery("(min-width:600px)");

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [400,-1800] : [100, -400]
  );
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <motion.div
      // data-aos="fade-in"
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="laptop">
        <img className="laptopImg" src={laptop} alt="" />
        <div className="laptopScreen">
          <img src={scan} alt="" className="laptopApp" />
        </div>
        <br />
        <ScrollDown device={"Laptop"} />
      </motion.div>
      <div className="projectDetail">
        <h1 className="projectTitle">CAAS</h1>
        <p className="projectDesc">
          Built a Centralized Automated Admission System for admissions into
          Bachelor courses for the Govt of Goa.
        </p>
        <button
          onClick={() => navigate("/MoreDetailsOnCAAS")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
