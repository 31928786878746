import React from "react";
import "../../../styles/aos.css"
import "./aboutPara.css";

function AboutPara() {
  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add("showAboutPara");
  //       } else {
  //         entry.target.classList.remove("showAboutPara");
  //       }
  //     });
  //   });

  //   try {
  //     const hiddenElements = document.querySelectorAll(".hiddenAboutPara");
  //     hiddenElements.forEach((el) => observer.observe(el));
  //   } catch (error) {
  //   }
  // });
  return (
    // <div>
    <p className="showAboutPara" >
      Hello there! <br />
      As an experienced developer, I'm passionate about building mobile and web
      applications that deliver exceptional user experiences. With a track
      record of success, I'm committed to creating seamless, intuitive
      interfaces that are easy to use and navigate.{" "}
      
    </p>
    // </div>
  );
}

export default AboutPara;
