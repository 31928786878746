import Aos from "aos";
import "../../styles/aos.css";

export default function Transitions() {
  window.scroll(0, 50);
  Aos.init({ duration: 2000 });
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("showAbout");
      } else {
        entry.target.classList.remove("showAbout");
      }
    });
  });

  try {
    const hiddenElements = document.querySelectorAll(".hiddenAbout");
    hiddenElements.forEach((el) => observer.observe(el));
  } catch (error) {
  }
}
