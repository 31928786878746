import React, { useEffect } from "react";
import Headers from "../parallax/headers/Headers.js";
import "./parallax.css";
import About from "./about/About";
import Projects from "./projects/Projects.js";
import Skills from "./skills/Skills.js";
import Contact from "./contact/Contact.js";

function MainParallax() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showHeaders");
        } else {
          entry.target.classList.remove("showHeaders");
        }
      });
    });

    try {
      const hiddenElements = document.querySelectorAll(".hiddenHeaders");
      hiddenElements.forEach((el) => observer.observe(el));
    } catch (error) {}
  });

  return (
    <>
      <section className="hiddenHeaders">
        <Headers />
      </section>
      <About />
      <Projects />
      <Skills />
      <Contact />
    </>
  );
}

export default MainParallax;
