import React from "react";
import "../moreInfo.css";
import laptop from "../assets/laptop.png";
import registration from "./assets/combined.png";
import { motion, useScroll, useTransform } from "framer-motion";
import Aos from "aos";
import "../../../styles/aos.css";
import { IoGlobeOutline } from "react-icons/io5";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useEffect } from "react";
import Transitions from "../Transitions";
import { useMediaQuery } from "@mui/material";

export default function SpacesnhomesMoreInfor() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const x1 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [50, -50] : [600, -600]
  );

  return (
    <div style={{ opacity: 1 }} className="hiddenAbout">
      <div
        className="project"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
      >
        <div className="projectDetail">
          <h1 className="projectTitle">Spacesnhomes</h1>
          <p className="projectDesc">
            Developed a freelance project for users to post real estate
            classifieds
          </p>
          {!isDesktop && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "end",
                right: 0,
                marginTop: "200px",
                zIndex: 100,
              }}
              className="scrollMobileGif"
            >
              <ScrollDown device={"Laptop"} />
            </div>
          )}
        </div>
        <motion.div style={{ x: x1 }} className="moreInfolaptop">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={registration} alt="" className="moreInfolaptopApp" />
          </div>
          <br />
          <ScrollDown device={"Laptop"} />
        </motion.div>
        <div className="moreInfolaptopMobile">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={registration} alt="" className="moreInfolaptopApp" />
          </div>
        </div>
      </div>

      <div className="projectDetail">
        {/* <h1 className="projectTitle">Sahakar Jal</h1> */}
        <p
          style={{
            minHeight: "10vh",
          }}
          id="moreInfoId"
          className="projectDesc"
          // data-aos="fade-in"
        >
          Spacesnhomes is a comprehensive mobile application designed to
          streamline the process of buying, selling, and renting residential and
          commercial properties. Whether you're looking for an apartment, plot,
          villa, bungalow, or business premises, Spacesnhomes provides a
          user-friendly platform for users to post and browse classified ads
          effortlessly.
        </p>
        <IoGlobeOutline
          id="githubBtn"
          className="moreDetails"
          onClick={() => window.open("https://spacesnhomes.com")}
          style={{
            paddingTop: "20px",
            maxWidth: "50px",
          }}
          fontSize={"35px"}
          width={"100%"}
        />
      </div>
    </div>
  );
}
