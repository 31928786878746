import React from "react";
import "../flutter.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useMediaQuery } from "@mui/material";

export default function OpenMap() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const x = useTransform(scrollYProgress, [0, 1], isDesktop ? [-1000,800] : [-400, 400]);

  return (
    <motion.div
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="phone">
        <img className="mobileTemplate" src={phone} alt="" />
        <div className="phoneScreen">
          <img src={scan} alt="" className="phoneApp" />
        </div>
        <ScrollDown device={"Phone"} />
      </motion.div>
      <div id="cipherDeets" className="projectDetail">
        <h1 className="projectTitle">OMP</h1>
        <p className="projectDesc">
          OpenMapTrack - An android application built for Freethink LLP during
          my internship for the purpose of tagging trees for ecological
          purposes.
        </p>
        <button
          onClick={() => navigate("/MoreDetailsOnOpenMap")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
