import React, { useEffect } from "react";
import "./about.css";
import Aos from "aos";
import "../../styles/aos.css";
import AboutPara from "./aboutPara/AboutPara";
import { motion, useScroll, useTransform } from "framer-motion";

function About() {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [-200, 600]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showAbout");
        } else {
          entry.target.classList.remove("showAbout");
        }
      });
    });

    try {
      const hiddenElements = document.querySelectorAll(".hiddenAbout");
      hiddenElements.forEach((el) => observer.observe(el));
    } catch (error) {}
  });
  return (
    <>
      <div className="aboutDiv">
        {/* <section className="hiddenAbout"> */}
        <motion.h1 style={{ x: x }} className="aboutHeader">
          About Me
        </motion.h1>
        <AboutPara />

        {/* </section> */}
      </div>
    </>
  );
}

export default About;
