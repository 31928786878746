import React from "react";
// import "../flutter.css";
import "../projects.css";
import laptop from "../assets/laptop.png";
import scan from "./assets/fullscreenpic.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ScrollDown from "../scroll-icon/ScrollDown";

import { motion, useScroll, useTransform } from "framer-motion";

export default function SahakarJal() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");
  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [800, -1200] : [500, -700]
  );
  return (
    <motion.div
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="laptop">
        <img className="laptopImg" src={laptop} alt="" />
        <div className="laptopScreen">
          <img src={scan} alt="" className="laptopApp" />
        </div>
        <br />
        <ScrollDown device={"Laptop"} />
      </motion.div>
      <div id="sahakarProjDetail" className="projectDetail">
        <h1 className="projectTitle">Sahakar Jal</h1>
        <p className="projectDesc">
          Ecommerce website for a packaged drinking water and beverages
          manufacturer.
        </p>
        <button
          onClick={() => navigate("/MoreDetailsOnSahakarJal")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
