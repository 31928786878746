import React from "react";
import "../projects.css";
import laptop from "../assets/laptop.png";
import scan from "./assets/combined.png";
import { useNavigate } from "react-router-dom";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useMediaQuery } from "@mui/material";

import { motion, useScroll, useTransform } from "framer-motion";

export default function Spacesnhomes() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");
  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-1800, 300] : [-1200, 200]
  );

  return (
    <motion.div
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="laptop">
        <img className="laptopImg" src={laptop} alt="" />
        <div className="laptopScreen">
          <img src={scan} alt="" className="laptopApp" />
        </div>
        <br />
        <ScrollDown device={"Laptop"} />
      </motion.div>
      <div id="taProjDetail" className="projectDetail">
        <h1 id="TechAnalysisId" className="projectTitle">
          Spacesnhomes
        </h1>
        <p className="projectDesc">
          A freelance project I built that allows users to post and view real
          estate classifieds
        </p>
        <button
          onClick={() => navigate("/MoreDetailsOnSpacesnhomes")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
