import React from "react";
import './Headers.css'
function Headers() {
  return (
    <>
    <div className="headersDiv">
      <p className="nameHeader">Vedang Sardessai</p>
      <p className="tagHeader">Flutter & React Developer</p>
    </div>
    </>
  );
}

export default Headers;
