import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "./skills.css";
import reactJs from "./assets/react.png";
import flutter from "./assets/flutter.png";
import firebase from "./assets/firebase.png";
import python from "./assets/python.png";
import docker from "./assets/docker.png";
import git from "./assets/git.png";
import nextjsImg from "./assets/nextjs.png";
import appwriteImg from "./assets/appwrite.png";
import tailwindImg from "./assets/tailwind.png";
import tsImg from "./assets/typescript.jpg";
import { useMediaQuery } from "@mui/material";

function Skills() {
  const { scrollYProgress } = useScroll();

  const isDesktop = useMediaQuery("(min-width:600px)");

  const x1 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-500, 0] : [-500, 0]
  );
  const x2 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [1000, 0] : [1000, 0]
  );
  const x3 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-1500, 0] : [-1500, 0]
  );
  const x4 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [2000, 0] : [2000, 0]
  );
  const x5 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-2500, 0] : [-2500, 0]
  );
  const x6 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [3000, 0] : [3000, 0]
  );
  const x7 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-3500, 0] : [-3500, 0]
  );
  const x8 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [4000, 0] : [4000, 0]
  );
  const x9 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-4500, 0] : [-4500, 0]
  );
  const x10 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [5000, 0] : [5000, 0]
  );
  const x11 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [-5500, 0] : [-5500, 0]
  );
  const x12 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [6000, 0] : [6000, 0]
  );

  return (
    <>
      <motion.h1
        id="skillsMainHeader"
        style={{ x: x1 }}
        className="skillHeader"
      >
        Skills
      </motion.h1>
      <div className="skillsDiv">
        <motion.div className="skillDiv" style={{ x: x2 }}>
          <h1 className="skillsTitle">
            FLutter
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={flutter}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x3 }}>
          <h1 className="skillsTitle">
            Next.JS
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={nextjsImg}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x4 }}>
          <h1 className="skillsTitle">
            ReactJS
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
                paddingLeft: "5px",
              }}
              src={reactJs}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x5 }}>
          <h1 className="skillsTitle">
            Appwrite{" "}
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={appwriteImg}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x6 }}>
          <h1 className="skillsTitle">
            Firebase{" "}
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={firebase}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x7 }}>
          <h1 className="skillsTitle">
            Tailwind
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={tailwindImg}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x8 }}>
          <h1 className="skillsTitle">
            Typescript
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
                marginLeft: "10px",
              }}
              src={tsImg}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x9 }}>
          <h1 className="skillsTitle">
            Python{" "}
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={python}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x10 }}>
          <h1 className="skillsTitle">
            Docker
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={docker}
              alt=""
            />
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x11 }}>
          <h1 className="skillsTitle">
            Vercel{" "}
            <svg
              style={{
                width: isDesktop ? "30px" : "20px",
                height: isDesktop ? "30px" : "20px",
                position: "absolute",
                paddingRight: "20px",
              }}
              viewBox="0 0 76 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M37.5274 0L75.0548 65H0L37.5274 0Z" fill="black" />
            </svg>
          </h1>
        </motion.div>
        <motion.div className="skillDiv" style={{ x: x12 }}>
          <h1 className="skillsTitle">
            GIT
            <img
              style={{
                height: isDesktop ? "30px" : "20px",
                width: isDesktop ? "30px" : "20px",
                position: "absolute",
              }}
              src={git}
              alt=""
            />
          </h1>
        </motion.div>
      </div>
    </>
  );
}

export default Skills;
