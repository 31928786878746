import React from "react";
import "./projects.css";
import CipherGred from "./2-ciphergred/CipherGred";
import SahakarJal from "./1-sahakarJal/SahakarJal.js";
import { motion, useScroll, useTransform } from "framer-motion";
import StockTra from "./5-stocktra/StockTra.js";
import Caas from "./3-caas/Caas.js";
import TechnicalAnalysis from "./6-stockTA/TechnicalAnalysis.js";
import OpenMap from "./4-openMapTrack/OpenMap";
import Spacesnhomes from "./spacesnhomes/Spacesnhomes.js";
import SpacesnhomesApp from "./spacesnhomesApp/SpacesnhomesApp.js";

export default function Projects() {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [-400, 800]);

  return (
    <div className="projects">
      <motion.h1 style={{ x }} className="projectsTitle">
        Check out my projects
      </motion.h1>
      <Caas />
      <CipherGred />
      <SahakarJal />

      <OpenMap />
      <StockTra />
      <TechnicalAnalysis />
      <SpacesnhomesApp/>
      <Spacesnhomes />
    </div>
  );
}
