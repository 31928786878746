import React from "react";
import "../projects.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import Aos from "aos";
import "../../../styles/aos.css";
import { useEffect } from "react";
import Transitions from "../Transitions";
import ScrollDown from "../scroll-icon/ScrollDown";

export default function SpacesnhomesAppMoreInfo() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const x2 = useTransform(scrollYProgress, [0, 1], [10, 130]);

  return (
    <div style={{ opacity: 1 }} className="hiddenAbout">
      <div className="mobDetails">
        <motion.div
          id="cipherGredProj"
          className="project"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{ width: 0 }}
        >
          <motion.div style={{ x: x2 }} className="phone">
            <img className="mobileTemplate" src={phone} alt="" />
            <div className="phoneScreen">
              <img src={scan} alt="" className="phoneApp" />
            </div>
            <ScrollDown device={"Phone"} />
          </motion.div>
          <div id="cipherDeets" className="projectDetail">
            <h1 className="projectTitle">CipherGred</h1>
            <p className="projectDesc">
              A mobile application for users to post and view real estate
              classifieds <br /> Scroll on picture to get a preview of the app.
            </p>
          </div>
        </motion.div>
        <div className="lorem">
          <p
            style={{
              minHeight: "70vh",
            }}
            id="moreInfoId"
            className="projectDesc"
            // data-aos="fade-in"
          >
            Experience The Future Of Real Estate Classifieds Spacesnhomes is a
            comprehensive mobile application designed to streamline the process
            of buying, selling, and renting residential and commercial
            properties. Whether you're looking for an apartment, plot, villa,
            bungalow, or business premises, Spacesnhomes provides a
            user-friendly platform for users to post and browse classified ads
            effortlessly.
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.dreamsavages.landmark_loom"
                  )
                }
                className="moreDetails"
              >
                Checkout the app here!
              </button>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
