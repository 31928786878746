import React from "react";
import "../projects.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import { AiFillGithub } from "react-icons/ai";
import Aos from "aos";
import "../../../styles/aos.css";
import { useEffect } from "react";
import Transitions from "../Transitions";
import ScrollDown from "../scroll-icon/ScrollDown";

export default function CipherGredMoreInfo() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  
  const x2 = useTransform(scrollYProgress, [0, 1], [10, 130]);

  return (
    <div style={{ opacity: 1, display: "block",  }} className="hiddenAbout">
      <div className="mobDetails">
        <motion.div
          id="cipherGredProj"
          className="project"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{ width: 0 }}
        >
          <motion.div style={{ x: x2 }} className="phone">
            <img className="mobileTemplate" src={phone} alt="" />
            <div className="phoneScreen">
              <img src={scan} alt="" className="phoneApp" />
            </div>
            <ScrollDown device={"Phone"} />
          </motion.div>
          <div id="cipherDeets" className="projectDetail">
            <h1 className="projectTitle">CipherGred</h1>
            <p className="projectDesc">
              Flutter application built to detect harmful ingredients in food
              labels. <br /> Scroll on picture to get a preview of the app.
            </p>
          </div>
        </motion.div>
        <div className="lorem">
          <p
            style={{
              minHeight: "70vh",
            }}
            id="moreInfoId"
            className="projectDesc"
            // data-aos="fade-in"
          >
            Built an app that takes images as inputs and scans for text after
            scanning and retrieving the text it scans for any harmful
            ingredients present. <br /> Used flutter and Google's text
            recognition ML-kit for scanning the text from the images of a
            product. <br /> Added anonymous sign in to the app. <br /> If the
            app scans any harmful ingredients in the picture uploaded by the
            user then a message suggesting that product is harmful to use will
            be displayed. <br /> The harmful ingredients will also be displayed
            in a scrollable list. <br /> If no harmful ingredients are found a
            message suggesting that the product is safe to use will be
            displayed. <br /> Tech Stack - Flutter , FireBase , Google Text
            Recognition ML-Kit.
            <div className="gridContainer">
              <AiFillGithub
                id="githubBtn"
                className="moreDetails"
                onClick={() =>
                  window.open("https://github.com/VedangSardessai/CipherGred")
                }
                fontSize={"35px"}
                width={"100%"}
              />

              <button
                onClick={() => window.open("http://lnkiy.in/Ciphergred")}
                className="moreDetails"
              >
                Watch the demo here
              </button>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
