import React from "react";
import "../projects.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import { AiFillGithub } from "react-icons/ai";
import Aos from "aos";
import "../../../styles/aos.css";
import { useEffect } from "react";
import Transitions from "../Transitions";
import ScrollDown from "../scroll-icon/ScrollDown.js";

export default function StockTraMoreInfor() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const x2 = useTransform(scrollYProgress, [0, 1], [10, 150]);

  return (
    <div className="forSizeOfDiv">
      <div style={{ opacity: 1 }} className="hiddenAbout">
        <motion.div
          className="project"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{ width: 0 }}
        >
          <motion.div style={{ x: x2 }} className="phone">
            <img className="mobileTemplate" src={phone} alt="" />
            <div className="phoneScreen">
              <img src={scan} alt="" className="phoneApp" />
            </div>
            <ScrollDown device={"Phone"} />
          </motion.div>
          <div id="cipherDeets" className="projectDetail">
            <h1 className="projectTitle">StockTra</h1>
            <p className="projectDesc">
              Flutter application built to check NIFTY50 stock prices and news
              articles related to NIFTY.
              <br /> Scroll on picture to get a preview of the app.
            </p>
          </div>
        </motion.div>
        <div className="lorem">
          <p
            id="moreInfoId"
            className="projectDesc"
            // data-aos="fade-in"
          >
            Developed a mobile app that allows users to track the stock prices
            of NIFTY50 list of stocks and also access news articles related to
            NIFTY. The first step for the user is to register and log in using
            Google sign-in, this serves as a means of authentication in the
            application. The user can check out the previous day stock prices of
            the stocks under the NIFTY50 list of stocks and the news articles
            related to NIFTY50. <br /> The application makes use of Yahoo
            Finance API to get the prices of the stocks and NEWS API to get
            articles of news related to NIFTY. <br /> Tech Stack - Flutter ,
            Firebase , Yahoo Finance API , NSETOOLS API.
            <div style={{ opacity: "1" }} className="gridContainer">
              <AiFillGithub
                id="githubBtn"
                className="moreDetails"
                onClick={() =>
                  window.open("https://github.com/VedangSardessai/StockTra")
                }
                fontSize={"35px"}
                width={"100%"}
              />

              <button
                onClick={() => window.open("http://lnkiy.in/StockTra")}
                className="moreDetails"
              >
                Watch the demo here
              </button>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
