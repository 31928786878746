import React from "react";
import "../moreInfo.css";
import laptop from "../assets/laptop.png";
import registration from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
// import Aos from "aos";
// import "../../../styles/aos.css";
import { useEffect } from "react";
import Transitions from "../Transitions";
import ScrollDown from "../scroll-icon/ScrollDown";
export default function CAASMoreInfor() {
  useEffect(() => {
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const x1 = useTransform(scrollYProgress, [0, 1], [100, -200]);
  //Use the hidden and show to translate the more details page
  return (
    <>
      <div style={{ opacity: 1 }} className="hiddenAbout">
        <div
          className="project"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{ width: 0 }}
        >
          <div className="projectDetail">
            <h1 className="projectTitle">
              Centralized Automated <br /> Admission System
            </h1>
            <p className="projectDesc">
              Built a Centralized Automated Admission System for admissions into
              Bachelor courses for the Govt of Goa.
            </p>
            <p
              style={{
                marginLeft: "80px",
              }}
              className="moreInfolaptopMobile"
            >
              <ScrollDown device={"Laptop"} />
            </p>
          </div>
          <motion.div style={{ x: x1 }} className="moreInfolaptop">
            <img className="moreInfolaptopImg" src={laptop} alt="" />
            <div className="moreInfolaptopScreen">
              <img src={registration} alt="" className="moreInfolaptopApp" />
            </div>

            <ScrollDown device={"Laptop"} />
          </motion.div>

          <div className="moreInfolaptopMobile">
            <img className="moreInfolaptopImg" src={laptop} alt="" />
            <div className="moreInfolaptopScreen">
              <img src={registration} alt="" className="moreInfolaptopApp" />
            </div>
          </div>
        </div>

        <div className="projectDetail">
          {/* <h1 className="projectTitle">Sahakar Jal</h1> */}
        </div>
        <p id="moreInfoId" className="projectDesc">
          Built a centralized admission system that takes care of admissions to
          all the professional colleges under Goa University. <br /> Interested
          students would register into the website and fill in their details and
          make payments and choose their exam centre of their choice. <br />{" "}
          Students get their hall tickets in a pdf format, after the results of
          the exam are declared various other details are filled by the
          students. <br /> Choice of stream and college would be filled by the
          students and if seats were available they would be allotted on
          successful payment of fees. <br /> This project was approved by the
          Directorate of Technical Education and the Government of Goa,
          eventually being used by over 2500 students. <br /> I worked on the
          front end development of the Centralised Automated Admission System
          using ReactJS , SASS and CSS. <br /> The above project was hosted on
          Amazon Web Services on an EC2 instance. <br /> Tech Stack - ReactJS ,
          SASS , Django , PostgreSQL , Knox , AWS.
        </p>
      </div>
    </>
  );
}
