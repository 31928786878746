import React from "react";
import "../moreInfo.css";
import laptop from "../assets/laptop.png";
import registration from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import Aos from "aos";
import "../../../styles/aos.css";
import { AiFillGithub } from "react-icons/ai";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useEffect } from "react";
import Transitions from "../Transitions";
import { useMediaQuery } from "@mui/material";

export default function StockVisualizerMoreInfor() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const x1 = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [400, -400] : [200, -200]
  );

  return (
    <div style={{ opacity: 1 }} className="hiddenAbout">
      <div
        className="project"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
      >
        <div className="projectDetail">
          <h1 className="projectTitle">
            Technical Analysis <br /> for Stocks
          </h1>
          <p className="projectDesc">
            Built a python project to plot stock market charts with technical
            indicators. <br /> Scroll on picture to get a preview of the
            project.
          </p>
          <p
            style={{
              marginLeft: "80px",
            }}
            className="moreInfolaptopMobile"
          >
            <ScrollDown device={"Laptop"} />
          </p>
        </div>
        <motion.div style={{ x: x1 }} className="moreInfolaptop">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={registration} alt="" className="moreInfolaptopApp" />
          </div>
          <br />
          <ScrollDown device={"Laptop"} />
        </motion.div>
        <div className="moreInfolaptopMobile">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={registration} alt="" className="moreInfolaptopApp" />
          </div>
        </div>
      </div>

      <div className="projectDetail">
        {/* <h1 className="projectTitle">Sahakar Jal</h1> */}
        <p
          style={{
            minHeight: "10vh",
          }}
          id="moreInfoId"
          className="projectDesc"
          // data-aos="fade-in"
        >
          Built a python project to plot stock charts using the yahoo api. I
          used a python library called talib for the technical analysis. This
          project plots the stock charts along with the technical indicators
          such as the moving averages.
        </p>
        <AiFillGithub
          id="githubBtn"
          className="moreDetails"
          onClick={() =>
            window.open("https://github.com/VedangSardessai/StockTra")
          }
          style={{
            paddingTop: "20px",
            maxWidth: "50px",
          }}
          fontSize={"35px"}
          width={"100%"}
        />
      </div>
    </div>
  );
}
