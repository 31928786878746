import React from "react";
import SahakarJalMoreInfor from "../1-sahakarJal/SahakarJalMoreInfor";
import CAASMoreInfor from "../3-caas/CAASMoreInfor";
import CipherGredMoreInfor from "../2-ciphergred/CipherGredMoreInfor";
import StockTraMoreInfor from "../5-stocktra/StockTraMoreInfor";
import MainParallax from "../../MainParallax";
import StockVisualizerMoreInfor from "../6-stockTA/StockVisualizerMoreInfor";
import OpenMapMoreInfor from "../4-openMapTrack/OpenMapMoreInfor";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import SpacesnhomesMoreInfor from "../spacesnhomes/SpacesnhomesMoreInfo";
import SpacesnhomesAppMoreInfo from "../spacesnhomesApp/SpacesnhomesAppMoreInfor";

export default function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<MainParallax />}></Route>
        <Route
          path="/MoreDetailsOnSahakarJal"
          element={<SahakarJalMoreInfor />}
        ></Route>
        <Route path="/MoreDetailsOnCAAS" element={<CAASMoreInfor />}></Route>
        <Route
          path="/MoreDetailsOnCipherGred"
          element={<CipherGredMoreInfor />}
        ></Route>
        <Route
          path="/MoreDetailsOnStockTra"
          element={<StockTraMoreInfor />}
        ></Route>

        <Route
          path="/MoreDetailsOnStockVisualizer"
          element={<StockVisualizerMoreInfor />}
        ></Route>

        <Route
          path="/MoreDetailsOnSpacesnhomes"
          element={<SpacesnhomesMoreInfor />}
        ></Route>
        <Route
          path="/MoreDetailsOnSpacesnhomesApp"
          element={<SpacesnhomesAppMoreInfo />}
        ></Route>

        <Route
          path="/MoreDetailsOnOpenMap"
          element={<OpenMapMoreInfor />}
        ></Route>
      </Routes>
    </AnimatePresence>
  );
}
