import React, { useEffect } from "react";
import "../moreInfo.css";
import laptop from "../assets/laptop.png";
import scan from "./assets/fullscreenpic.png";
import beverages from "./assets/beverages.png";
import Transitions from "../Transitions";
import { motion, useScroll, useTransform } from "framer-motion";
import Aos from "aos";
import "../../../styles/aos.css";
import ScrollDown from "../scroll-icon/ScrollDown";

export default function SahakarJalMoreInfor() {
  const { scrollYProgress } = useScroll();
  const x1 = useTransform(scrollYProgress, [0, 1], [50, -300]);
  const x2 = useTransform(scrollYProgress, [0, 1], [-300, 50]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    Transitions();
  });
  return (
    <div style={{ opacity: 1 }} className="hiddenAbout">
      <div
        id="sahakarJalMob"
        className="project"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
      >
        <div className="projectDetail">
          <h1 className="projectTitle">Sahakar Jal</h1>
          <p className="projectDesc">
            Built an ecommerce website for a packaged drinking water and
            beverages manufacturer.
          </p>
          <p style={{
            marginLeft:'80px'
          }} className="moreInfolaptopMobile">
            <ScrollDown device={"Laptop"} />
          </p>
        </div>
        <motion.div style={{ x: x1 }} className="moreInfolaptop">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div id="moreInfoLaptop" className="moreInfolaptopScreen">
            <img src={scan} alt="" className="moreInfolaptopApp" />
          </div>
          <br />
          <ScrollDown device={"Laptop"} />
        </motion.div>

        <div className="moreInfolaptopMobile">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={scan} alt="" className="moreInfolaptopApp" />
            {/* <br /> */}
          </div>
        </div>
      </div>
      <div
        id="sahakarsecondlaptopMob"
        className="project"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
      >
        <div className="projectDetail">
          {/* <h1 className="projectTitle">Sahakar Jal</h1> */}
          <p id="InfoProjDesc" className="projectDesc">
            <br />
            <ScrollDown device={"Laptop"} />
          </p>
        </div>
        <motion.div style={{ x: x2 }} className="moreInfolaptop">
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={beverages} alt="" className="moreInfolaptopApp" />
          </div>
        </motion.div>

        <div
          id="secondSahakarJalLaptopForMargin"
          className="moreInfolaptopMobile"
        >
          <img className="moreInfolaptopImg" src={laptop} alt="" />
          <div className="moreInfolaptopScreen">
            <img src={beverages} alt="" className="moreInfolaptopApp" />
          </div>
        </div>
      </div>

      <div className="projectDetail">
        {/* <h1 className="projectTitle">Sahakar Jal</h1> */}
      </div>
      <p className="projectDesc">
        Built company’s ecommerce website Built an ecommerce website for Sahakar
        Jal, a packaged drinking water and beverages manufacturing company.
        <br /> The website was designed to showcase all of the products sold by
        Sahakar Jal and allow customers to easily order products online.
        <br />
        Customers can create an account prior to ordering as this will save
        their address and contact details. <br />
        To make the payment process more convenient, we added an online payment
        facility using the Razorpay payment gateway. <br />
        The website is currently hosted on Vercel, but with the payment and
        product ordering being disabled, serves as a display of our work. <br />{" "}
        Tech Stack : ReactJS, NodeJS, ExpressJS, Firebase,Razorpay,Vercel.
      </p>
    </div>
  );
}
