import appScreenshot from "./assets/combined.jpg";
import React from "react";
import "../flutter.css";
import phone from "../assets/phone.png";
import { motion, useScroll, useTransform } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ScrollDown from "../scroll-icon/ScrollDown";
import { useMediaQuery } from "@mui/material";

export default function StockTra() {
  const { scrollYProgress } = useScroll();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isDesktop ? [800, -500] : [400, -350]
  );
  const navigate = useNavigate();

  return (
    <motion.div
      id="stockTraProj"
      className="project"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ width: 0 }}
    >
      <motion.div style={{ x: x }} className="phone">
        <img className="mobileTemplate" src={phone} alt="" />
        <div className="phoneScreen">
          <img src={appScreenshot} alt="" className="phoneApp" />
        </div>
        <ScrollDown device={"Phone"} />
      </motion.div>
      <div className="projectDetail">
        <h1 className="projectTitle">StockTra</h1>
        <p className="projectDesc">
          Flutter application built to check NIFTY50 stock prices and news
          articles related to NIFTY.
        </p>
        <button
          onClick={() => navigate("/MoreDetailsOnStockTra")}
          className="moreDetails"
        >
          Learn more
        </button>
      </div>
    </motion.div>
  );
}
