import React from "react";
import "../projects.css";
import phone from "../assets/phone.png";
import scan from "./assets/combined.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import { AiFillGithub } from "react-icons/ai";
import Aos from "aos";
import "../../../styles/aos.css";
import { useEffect } from "react";
import Transitions from "../Transitions";
import ScrollDown from "../scroll-icon/ScrollDown";
export default function OpenMapMoreInfor() {
  useEffect(() => {
    Aos.init({ duration: 2000 });

    Transitions();
  });

  const { scrollYProgress } = useScroll();
  const x2 = useTransform(scrollYProgress, [0, 1], [10, 120]);

  return (
    <div style={{ opacity: 1 }} className="hiddenAbout">
      <motion.div
        className="project"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ width: 0 }}
      >
        <motion.div style={{ x: x2 }} className="phone">
          <img className="mobileTemplate" src={phone} alt="" />
          <div className="phoneScreen">
            <img src={scan} alt="" className="phoneApp" />
          </div>
          <ScrollDown />
        </motion.div>
        <div id="cipherDeets" className="projectDetail">
          <h1 className="projectTitle">OMP</h1>
          <p className="projectDesc">
            {" "}
            Internship android application built for the purpose of tagging
            trees for ecological purposes
            <br /> Scroll on picture to get a preview of the app.
          </p>
        </div>
      </motion.div>
      <div className="lorem">
        <p
          style={{
            minHeight: "70vh",
          }}
          id="moreInfoId"
          className="projectDesc"
          // data-aos="fade-in"
        >
          I led a team that created an android app for tagging trees for
          ecological purposes. <br /> The app's goal is to allow users to
          identify and track trees in their area for future research and
          conservation efforts. <br /> The app had a registration process where
          users could register either with email or sign in with google, this
          added an extra layer of integrity. <br /> Once registered, users could
          add the location of a tree, either pointing on the map or typing in
          the address, this allowed ease of tagging the trees. <br /> After
          adding the location of the tree, users could then enter information
          related to the tree such as its name, height, and other
          characteristics. <br /> This data would be stored in the Cloud
          Firestore, I worked on connecting the app to Firestore to store the
          data that was converted into the geojson format. <br /> Tech Stack -
          Java , FireBase , Android SDK.
          {/* <div className="gridContainer"> */}
          <AiFillGithub
            id="githubBtn"
            className="moreDetails"
            visibility={"hidden"}
            style={{
              paddingTop: "20px",
              maxWidth: "50px",
            }}
            fontSize={"35px"}
            width={"100%"}
          />
          <button
            onClick={() => window.open("http://lnkiy.in/StockTra")}
            className="moreDetails"
            style={
              {
                // fontSize: "18px",
              }
            }
          >
            Watch the demo here
          </button>
          {/* </div> */}
        </p>
      </div>
    </div>
  );
}
