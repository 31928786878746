import React from "react";
import "../about/about.css";
import { motion, useScroll, useTransform } from "framer-motion";
import "./contact.css";
import githubLogo from "./assets/github.png";
import gmailLogo from "./assets/gmailLogo.png";
import linkedinLogo from "./assets/linkedinLogo.png";
import twitterLogo from "./assets/x.png";
function Contact() {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [-5000, 0]);

  const linkedIn = "https://www.linkedin.com/in/vedangsardessai/";

  const gitHub = "https://github.com/VedangSardessai";

  const myEmail = "mailto:vedangsardessai@gmail.com";
  const myTwitter = "https://twitter.com/vedangsardessai";

  const openNewTab = (linkName) => window.open(linkName);
  return (
    <>
      <div className="contactDiv">
        <motion.h1 style={{ x: x }} className="contactHeader">
          Connect with me
        </motion.h1>
        <div className="children">
          <motion.span
            whileHover={{ scale: 0.9 }}
            whileTap={{ scale: 0.8 }}
            style={{ x: 0 }}
          >
            <img
              src={githubLogo}
              alt="GITHUB"
              onClick={() => openNewTab(gitHub)}
              className="connectLogos"
            />
          </motion.span>
          <motion.span
            whileHover={{ scale: 0.9 }}
            whileTap={{ scale: 0.8 }}
            style={{ x: 0 }}
          >
            <img
              src={linkedinLogo}
              alt="LINKEDIN"
              onClick={() => openNewTab(linkedIn)}
              className="connectLogos"
            />
          </motion.span>

          <motion.span
            whileHover={{ scale: 0.9 }}
            whileTap={{ scale: 0.8 }}
            style={{ x: 0 }}
          >
            <img
              src={twitterLogo}
              alt="TWITTER"
              onClick={() => openNewTab(myTwitter)}
              className="connectLogos"
            />
          </motion.span>
          <motion.span
            whileHover={{ scale: 0.9 }}
            whileTap={{ scale: 0.8 }}
            style={{ x: 0 }}
          >
            <img
              src={gmailLogo}
              alt="GMAIl"
              onClick={() => openNewTab(myEmail)}
              className="connectLogos"
            />
          </motion.span>
          
        </div>
      </div>
    </>
  );
}

export default Contact;
