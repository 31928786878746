import React from "react";
import scrollDown from "../assets/scrollDown.gif";

export default function ScrollDown({device}) {
  return (
    <>
      <img
        style={{
          height: "50px",
          opacity: "1",
          marginBottom: "-20px",
        }}
        src={scrollDown}
        alt=""
      />
      <p
        style={{
          // height: "30vh",
          paddingLeft: "10px",
          paddingRight: "10px",
          opacity: "0.5",
        }}
      >
        Scroll {device} For Preview
      </p>
    </>
  );
}
